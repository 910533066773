import { Link } from 'gatsby';
import React from 'react';
import TagsNav from '../../TagsNav/TagsNav';
import Pagination from '../../Pagination/Pagination';
import TermWrapper from '../../TermWrapper/TermWrapper';

const BlogArchive = ({ archiveNodes, archiveTerms, pageContext, withTaxonomies }) => {
  return (
    <>
      {withTaxonomies && <TagsNav tags={archiveTerms} archivePath="/blog/" />}
      <div className="wrapper">
        <div className="container">
          {archiveNodes?.nodes?.map(({ date, title, uri, blogTags, blogPostFields }, index) => (
            <div key={index} className="archive-wrapper">
              <div className="blogarchive">
                <div className="postdate">{date}</div>
                <div className="description">
                  <Link to={uri}>{title}</Link>
                </div>
              </div>
              <div className="blogarchive">
                <div className="postdate" />
                <TermWrapper terms={blogTags} />
              </div>
              <div className="blogarchive">
                <div className="postdate" />
                <div className="postauthor">{blogPostFields.author}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination pageContext={pageContext} pageInfo={archiveNodes.pageInfo} />
    </>
  );
};

export default BlogArchive;
